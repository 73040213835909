import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import ProductSectionTemplate from "../templates/ProductsSectionTemplate";
import OrderDetail from "./components/OrderDetail";
import withTracker from "../../blueberry/connectPromise";
import Blueberry from "../../blueberry/index";
import OrdersList2 from "./OrdersList2";
import useQueryString from "../hooks/useQueryString";
const viewMapped = {
    trackingNumber: "trackingNumbers",
    bill: "bills",
    order: "orders",
};

function OrderTabs({
    token,
    lang,
    order,
    user,
    accountCredit,
    _t,
    getBasePath,
    history,
    viewItem,
    restockItem,
    setWindowTop,
    jsonSettings,
}) {
    const [reports, setReports] = useState([]);
    const [currentCompany, setCurrentCompany] = useState(null);
    const [hasError, setHasError] = useState(false);
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(
        accountCredit && accountCredit.byPassLoading ? false : true
    );

    const [accountData, setAccountData] = useState(
        accountCredit && accountCredit.byPassLoading ? accountCredit : {}
    );

    const [ordersFromErp, setOrdersFromErp] = useState({
        trackingNumber: [],
        order: [],
        bill: [],
        QUOTATION: [],
    });
    const [isLoadingOrders, setIsLoadingOrders] = useState(false);
    const [view, setCurrentView] = useQueryString("view", "QUOTATION");
    const [page, setPage] = useQueryString("page", 1, "int");
    const [limit, setLimit] = useQueryString("limit", 15, "int");
    const [queryText, setQueryText] = useQueryString("qry", "");
    const [fromUrl] = useQueryString("from", "");

    const orderId =
        token && token.length > 6
            ? token.slice(token.length - 5, token.length).toUpperCase()
            : token;

    const getAccountData = (currCom) => {
        setIsLoading(true);
        Blueberry.credit({
            companyId: currCom.companyId,
            typeOfRequest: "crmByGroup",
        })
            .then((resp) => {
                setAccountData(resp);
                setIsLoading(false);
            })
            .catch((err) => {
                setHasError(true);
                setIsLoading(false);
                console.log(err);
            });
    };

    const getReports = () => {
        Blueberry.validateReports({
            type: "apirest",
            name: "getClientCrm",
        })
            .then((resp) => {
                setReports(resp ? resp.integrations : []);
                const currCom =
                    resp && resp.integrations && resp.integrations.length > 0
                        ? resp.integrations[0]
                        : null;
                setCurrentCompany(currCom);
                // getAccountData(currCom);
            })
            .catch((err) => {
                console.log(err);
                setHasError(true);
            });
    };

	const getOrdersFromErp = (currCom, view) => {
		setIsLoadingOrders(true);
		Blueberry.orderFromERP({
			companyId: currCom.companyId,
			typeOfRequest: view,
			page,
			limit,
		})
			.then((resp) => {
				setOrdersFromErp((prev) => ({
					...prev,
					[view]:
						resp && viewMapped[view] && resp[viewMapped[view]]
							? resp[viewMapped[view]]
							: [],
				}));

				setIsLoadingOrders(false);
			})
			.catch((err) => {
				console.log(err);
				setIsLoadingOrders(false);
			});
	};

	useEffect(() => {
		if (view === "QUOTATION") {
			setIsLoadingOrders(true);
			Blueberry.searchOrders({
				page,
				perPage: limit,
				paymentFilters: [{ value: "QUOTATION" }],
				searchText: queryText,
			})
				.then((orders) => {
					setOrders(orders);
					setIsLoadingOrders(false);
				})
				.catch(() => {
					setIsLoadingOrders(false);
				});
		}
	}, [view, page, currentCompany, queryText]);

	useEffect(() => {
		if (view !== "QUOTATION" && currentCompany) {
			getOrdersFromErp(currentCompany, view);
		}
	}, [view, page, currentCompany]);

	useEffect(() => {
		getReports();
	}, []);

	useEffect(() => {
		if (currentCompany) {
			if (
				accountCredit == null ||
				accountCredit.companyId !== currentCompany.companyId
			) {
				getAccountData(currentCompany);
			}
		}
	}, [currentCompany]);

    if (token) {
        return (
            <>
                <Helmet>
                    <title>
                        {_t("myOrders")} {orderId}
                    </title>
                </Helmet>
                <OrderDetail
                    token={token}
                    orderId={orderId}
                    getBasePath={getBasePath}
                    history={history}
                    viewItem={viewItem}
                    restockItem={restockItem}
                    order={order[token]}
                    user={user}
                    jsonSettings={jsonSettings}
                />
            </>
        );
    }

    return (
        <>
            <Helmet>
                <title>{_t("myOrders")}</title>
            </Helmet>

            <OrdersList2
                orders={orders}
                user={user}
                reports={reports}
                view={view}
                setCurrentView={setCurrentView}
                queryText={queryText}
                setQueryText={setQueryText}
                currentCompany={currentCompany}
                ordersFromErp={ordersFromErp[view]}
                isLoadingOrders={isLoadingOrders}
                setCurrentCompany={setCurrentCompany}
                isLoading={isLoading}
                accountData={accountData}
                setHasError={setHasError}
                hasError={hasError}
                page={page}
                limit={limit}
                setPage={setPage}
                getBasePath={getBasePath}
                setWindowTop={setWindowTop}
                lang={lang}
                accountCredit={accountCredit}
                fromUrl={fromUrl}
                jsonSettings={jsonSettings}
            />
        </>
    );
}

class OrderView extends ProductSectionTemplate {
    constructor(props) {
        super(props);
        const lastKnownProduct = Blueberry.getLastKnownProduct();
        this.state = {
            key: 0,
            lastKnownProduct,
            isHeaderOpen: true,
            ...this.productDefaultState,
        };
    }

    restockItem = (productId, quantity, product) => {
        return new Promise((resolve) => {
            this.addToCart(productId, quantity, product).then(() => {
                resolve();
            });
        });
    };

    renderBody() {
        const { token, lang } = this.props.match.params;
        const { order, user, accountCredit, orders } = this.props.store;
        const { jsonSettings } = this.props;

        return (
            <OrderTabs
                token={token}
                lang={lang}
                order={order}
                user={user}
                accountCredit={accountCredit}
                orders={orders}
                _t={this._t}
                getBasePath={this.getBasePath}
                setWindowTop={this.setWindowTop}
                history={this.props.history}
                viewItem={this.viewItem}
                restockItem={this.restockItem}
                jsonSettings={jsonSettings}
            />
        );
    }
}

export default withTracker((props) => {})(OrderView);
